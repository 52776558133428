<script>
import AsyncExportButton from "@/components/async-export-button.vue";
import DropdownCaptacao from "@/components/dropdown-captacao.vue";
import Modal from "@/components/g-modal.vue";
import Pagination from "@/components/pagination.vue";
import Table from "@/components/table-v.vue";
import toMonth from "@/helpers/toMonth";
import { mapGetters } from "vuex";

const BENEFICIO = 4; // Captação

export default {
  components: {
    AsyncExportButton,
    DropdownCaptacao,
    Pagination,
    Modal,
    Table
  },
  data() {
    const intervaloCompetencia = this.$store.getters.intervaloCompetencia;
    const pages = new Map();

    return {
      colaborador: "",
      colaboradores: [],
      colaboradoresSelecionados: [],
      competencias: [],
      competenciaRecalculo: [...intervaloCompetencia],
      dateMenu: false,
      funcionarioId: null,
      intervaloCompetencia,
      opts: {},
      page: 1,
      pages,
      recalcularTodas: false,
      recalculoStatus: [],
      rows: [],
      searchInputValue: "",
      status: {},
      tableClasses: ""
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      return [
        {
          text: "Atualizar",
          icon: "mdi-refresh",
          action: () => {
            this.doRefresh();
          }
        },
        {
          text: "Exportação Assíncrona",
          icon: "mdi-cloud-refresh-outline",
          action: () => {
            this.$refs.asyncExportButton?.exportCsv();
          }
        },
        {
          text: "Recalcular",
          icon: "mdi-cog-sync",
          action: () => {
            this.$refs.recalcModal.open();
          }
        }
      ];
    },
    beneficio() {
      return BENEFICIO;
    },
    cols() {
      const months = this.competencias.map(comp => ({
        key: comp,
        name: toMonth(comp),
        type: this.$fieldTypes.TEXT,
        sortable: false,
        align: 1
      }));

      return [
        {
          key: "rubrica",
          name: this.colaborador ?? "",
          type: this.$fieldTypes.TEXT,
          sortable: false
        },
        ...months
      ];
    },
    parsedStatus() {
      const getIcon = color => {
        switch (color) {
          case "yellow":
            return "mdi-alert";
          case "green":
            return "mdi-check-circle";
          case "orange":
            return "mdi-calculator";
          case "blue":
            return "mdi-lock";
          case "red":
          default:
            return "mdi-close-circle";
        }
      };
      const config = this.recalculoStatus.reduce(
        (acc, { codigo, legenda, cor }) => ({
          ...acc,
          [codigo]: {
            text: legenda,
            color: cor,
            disabled: false,
            icon: getIcon(cor)
          }
        }),
        {}
      );
      const target = Object.entries(this.status).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value
            ? config[value]
            : { text: "", color: "", disabled: true, icon: "" }
        }),
        {}
      );
      const handler = {
        get(target, key) {
          if (key in target) {
            return target[key];
          }

          return { text: "", color: "", disabled: true, icon: "" };
        }
      };
      return new Proxy(target, handler);
    },
    projetoId() {
      return this.$route.params.id;
    },
    showPagination() {
      return !this.funcionarioId && this.totalPages > 1;
    },
    totalPages() {
      return this.colaboradores.length;
    }
  },
  async created() {
    await Promise.all([this.getColaboradores(), this.getStatus()]);
    this.getPage();
  },
  methods: {
    async doRecalc(close) {
      try {
        const { save } = this.apiResource(
          `/v1/rh/clientes/${this.clientId}/dispendio/recalc`
        );
        const [competenciaIni, competenciaFim] = this.competenciaRecalculo;
        await save({
          colaboradores: this.colaboradoresSelecionados,
          competenciaIni,
          competenciaFim,
          trabalho: BENEFICIO,
          todasFolhas: this.recalcularTodas
        });
        this.$notify({
          group: "geral",
          duration: 7000,
          type: "sucess",
          title: "Solicitado recalculo com sucesso",
          text: "As competências serão recalculadas em breve..."
        });
        this.page = 1;
        this.getPage();
        close();
      } catch (error) {
        this.notify(error);
      }
    },
    async doRefresh() {
      await this.getColaboradores();
      this.getPage({ forceRefresh: true });
    },
    async doLoad(funcionarioId) {
      try {
        const [ini, fim] = this.intervaloCompetencia;
        let query = `competenciaIni=${ini}&competenciaFim=${fim}&funcionarioId=${funcionarioId}`;
        return await this.apiResource(
          `v1/captacao/${this.clientId}/timesheets/dispendios/rh`
        ).get({ query });
      } catch (error) {
        this.notify(error);
        return [];
      }
    },
    async getColaboradores() {
      try {
        const { get } = this.apiResource(`/v1/rh/${this.clientId}/selecao`);
        const response = await get();
        this.colaboradores = response.colaboradores
          .sort(({ nome: a }, { nome: b }) => a.localeCompare(b))
          .map(col => ({
            ...col,
            label: `${col.matricula || `Sem matrícula`} — ${col.nome}`
          }));
      } catch (error) {
        this.notify(error);
        this.colaboradores = [];
      }
    },
    async getPage({ forceRefresh = false } = {}) {
      try {
        if (!Array.isArray(this.colaboradores) || !this.colaboradores.length) {
          this.notify({
            error:
              "Não é possível carregar os dispêndios pois não foi possível carregar a lista de colaboradores. Recarregue a página."
          });
          return;
        }

        let colaborador = {};

        if (this.funcionarioId) {
          colaborador = this.colaboradores.find(
            ({ id }) => id === this.funcionarioId
          );
        } else {
          const index =
            this.page < 1
              ? 0
              : this.page > this.colaboradores.length
              ? this.colaboradores.length - 1
              : this.page - 1;
          colaborador = this.colaboradores[index];
        }

        this.colaborador = colaborador.nome;
        const funcionarioId = colaborador.id;

        if (forceRefresh || !this.pages.has(funcionarioId)) {
          const data = await this.doLoad(funcionarioId);
          this.pages.set(funcionarioId, data);
        }

        const page = this.pages.get(funcionarioId);
        this.competencias = page
          .reduce(
            (acc, { competencia }) =>
              acc.includes(competencia) ? acc : [...acc, competencia],
            []
          )
          .sort();

        this.status = page.reduce(
          (acc, { competencia, status }) => ({ ...acc, [competencia]: status }),
          { rubrica: null }
        );

        const { toCurrency } = this.$options.filters;
        // Código chumbado para ordenar as rúbricas
        const totals = [
          {
            group: "CUSTOS",
            label: "Total custo funcionário",
            order: 2
          },
          {
            group: "VENCIMENTOS",
            label: "Total de vencimentos",
            order: 1
          },
          {
            group: "GERAL",
            label: "Valor dedicado a P&D",
            order: 3
          }
        ];
        const percetualRow = {
          rubrica: "Percentual dedicado a P&D",
          order: Number.POSITIVE_INFINITY
        };
        this.rows = page
          .reduce(
            (
              groups,
              {
                competencia,
                nome,
                percentual,
                rubrica_agrupamento = "nenhuma",
                valor = 0,
                ...rest
              }
            ) => {
              let group = groups.find(({ id }) => id === rubrica_agrupamento);

              if (!group) {
                group = {
                  id: rubrica_agrupamento,
                  data: []
                };
                groups.push(group);
              }

              if (!group.order) {
                const found = totals.find(
                  ({ group: groupId }) => group.id === groupId
                );
                group.order = found?.order || Number.POSITIVE_INFINITY;
              }

              let found = group.data.find(row => row.rubrica === nome);

              if (!found) {
                const isTotal = totals.some(({ label }) => label === nome);
                found = {
                  ...rest,
                  rubrica: nome,
                  [competencia]: 0,
                  order: isTotal ? Number.POSITIVE_INFINITY : 0
                };
                group.data.push(found);
              }

              found[competencia] = toCurrency(valor);

              if (!percetualRow[competencia]) {
                percetualRow[competencia] = percentual
                  ? `${percentual}%`
                  : " -- ";
              }

              return groups;
            },
            [
              {
                id: "GERAL",
                data: this.competencias.length ? [percetualRow] : []
              }
            ]
          )
          .sort(({ order: a }, { order: b }) => a - b)
          .reduce(
            (acc, { data }) => [
              ...acc,
              ...data.sort(
                (
                  { order: orderA, rubrica: rubricaA },
                  { order: orderB, rubrica: rubricaB }
                ) => orderA - orderB + rubricaA.localeCompare(rubricaB)
              )
            ],
            []
          );
        this.tableClasses = totals
          .map(({ label }) =>
            this.rows.findIndex(({ rubrica }) => rubrica === label)
          )
          .filter(index => index >= 0)
          .map(index => `highlight-row-${index + 1}`);
      } catch (error) {
        this.notify(error);
        this.colaborador = "";
        this.competencias = [];
        this.rows = [];
      }
    },
    async getStatus() {
      try {
        this.recalculoStatus = [];
        const { get } = this.apiResource(`/v1/rh/statusfopag`);
        this.recalculoStatus = await get();
      } catch (error) {
        this.notify(error);
        this.recalculoStatus = [];
      }
    }
  },
  watch: {
    clientId() {
      this.pages.clear();
      this.page = 1;
      this.getPage();
    },
    intervaloCompetencia(next, prev) {
      if (
        Array.isArray(next) &&
        next.length === 2 &&
        next.some(month => !Array.isArray(prev) || !prev.includes(month))
      ) {
        this.pages.clear();
        this.getPage();
      }
    }
  }
};
</script>

<template>
  <div>
    <Table
      ref="table"
      :actionBarButtons="actionBarButtons"
      class="masterDetail"
      :class="tableClasses"
      :cols="cols"
      disableHover
      disablePagination
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :hasFilter="false"
      height="auto"
      :monthlyFilter.sync="intervaloCompetencia"
      :opts="opts"
      :rows="rows"
    >
      <template #header="{ head }">
        <v-tooltip bottom :disabled="parsedStatus[head.value].disabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                v-if="!parsedStatus[head.value].disabled"
                :color="parsedStatus[head.value].color"
                small
              >
                {{ parsedStatus[head.value].icon }}
              </v-icon>
              {{ head.text }}
            </span>
          </template>
          <span>{{ parsedStatus[head.value].text }}</span>
        </v-tooltip>
      </template>

      <v-autocomplete
        v-model="funcionarioId"
        clearable
        dense
        hide-details
        :items="colaboradores"
        item-value="id"
        item-text="label"
        prefix="Colaborador:"
        prepend-icon="mdi-account-box-outline"
        placeholder="todos"
        style="max-width: 510px"
        @change="getPage()"
      ></v-autocomplete>
      <v-spacer></v-spacer>
      <AsyncExportButton
        ref="asyncExportButton"
        :beneficio="beneficio"
        :competencia="intervaloCompetencia"
        class="hidden"
        type="captacao-dispendios-rh"
      />
      <Modal ref="recalcModal">
        <p><b> Selecione o período para finalização:</b></p>
        <v-menu
          v-model="dateMenu"
          :close-on-click="competenciaRecalculo.length == 2"
          :close-on-content-click="false"
          transition="scroll-y-transition"
          offset-y
          right
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="competenciaRecalculo | toMonthPeriod"
              dense
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              @click:prepend.stop="on.click"
              v-on="on"
              hide-details
              class="mb-n1 mt-0 mr-3"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="competenciaRecalculo"
            range
            type="month"
            @click:month="dateMenu = competenciaRecalculo.length != 2"
            no-title
          ></v-date-picker>
        </v-menu>
        <br />
        <p>
          Selecione um ou mais colaboradores. Deixe em branco para selecionar
          todos.
        </p>
        <v-autocomplete
          v-model="colaboradoresSelecionados"
          :disabled="!colaboradores || colaboradores.length === 0"
          :items="colaboradores"
          item-text="nome"
          item-value="id"
          placeholder="Todos colaboradores"
          dense
          multiple
          hide-details
          clearable
          prepend-icon="mdi-account"
          :search-input.sync="searchInputValue"
          @blur="searchInputValue = ''"
        ></v-autocomplete>
        <v-radio-group v-model="recalcularTodas" row>
          <v-radio label="Apenas folhas alteradas" :value="false"></v-radio>
          <v-radio label="Todas as folhas" :value="true"></v-radio>
        </v-radio-group>

        <template #buttons="{ close }">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="pr-5" dark depressed @click="close()">
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="doRecalc(close)"
          >
            Executar
          </v-btn>
        </template>
      </Modal>
      <DropdownCaptacao />
    </Table>
    <div v-if="showPagination" class="pagination-container">
      <Pagination v-model="page" :length="totalPages" @change="getPage()" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.pagination-container {
  align-content: center;
  background-color: #fff;
  height: 3rem;

  .pagination {
    margin-left: auto;
  }
}

.pagination::v-deep .v-input input {
  padding: 0.125rem 0 0.125rem 0.25rem;
  width: 2rem;
}

.masterDetail::v-deep {
  .v-card__text.d-flex {
    align-items: center;

    div.monthly-filter-field {
      margin-top: 0.5rem !important;
    }

    > div:last-of-type {
      display: flex;
      flex: 1;
    }
  }

  .table-v-tr {
    &:hover {
      background-color: transparent !important;
      cursor: auto;
    }
  }

  @for $i from 1 through 16 {
    &.highlight-row-#{$i} .table-v-tr {
      &:hover {
        background-color: transparent !important;
        cursor: auto;
      }

      &:nth-child(#{$i}) {
        background-color: var(--v-table-header-base);

        &:hover {
          background-color: var(--v-table-header-base) !important;
        }

        * {
          font-weight: bolder !important;
        }
      }
    }
  }
}
</style>
