import { render, staticRenderFns } from "./recursoshumanos.vue?vue&type=template&id=a6984c6a&scoped=true&"
import script from "./recursoshumanos.vue?vue&type=script&lang=js&"
export * from "./recursoshumanos.vue?vue&type=script&lang=js&"
import style0 from "./recursoshumanos.vue?vue&type=style&index=0&id=a6984c6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6984c6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VBtn,VDatePicker,VIcon,VMenu,VRadio,VRadioGroup,VSpacer,VTextField,VTooltip})
